import { Container } from '@material-ui/core'
import { graphql } from 'gatsby'
import React, { useEffect, useMemo, useState } from 'react'
import NavigationBar from '../components/navigation-bar/navigation-bar'
import SingleArticle from '../components/single-article/single-article'

interface Node {
    id: string
    html: string
    frontmatter: {
        title: string
        subtitle?: string
        date?: string
    }
}

interface Props {
    data: {
        markdownRemark: Node
    }
}

export default function SingleTemplate({data}: Props) {
    const post = data.markdownRemark
    NavigationBar.useSetTitle(post.frontmatter.title)
    
    const [displayTypo, setDisplayTypo] = useState(false)

    useEffect(() => {
        const h = setTimeout(() => {
            setDisplayTypo(true)
        }, 10)
        return () => clearTimeout(h)
    }, [])

    const articleStyle = useMemo<React.CSSProperties>(() => {
        return {
            transition: `${300}ms opacity`,
            opacity: (displayTypo) ? 1 : 0
        }
    }, [displayTypo])

    return <>
        <Container maxWidth='sm'>
            <SingleArticle
                title={post.frontmatter.title}
                content={post.html}
                publishedOn={post.frontmatter.date}
                style={articleStyle}
            />
        </Container>
    </>
}

export const pageQuery = graphql`query BlogPostBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
        id
        html
        frontmatter {
            title
            date
        }
    }
}`
